import React from "react"
import { Container, Row, Col } from "reactstrap"

const styles = {
  title: {
    textAlign: "center",
    marginBottom: "2rem",
  },
}
const Section = ({ children, title, variant }) => {
  return (
    <section style={{ ...styles.section, backgroundColor: "" }}>
      <Container>
        {title ? (
          <Row className="my-5">
            <Col className="section-header mx-auto">
              <h2>{title}</h2>
            </Col>
          </Row>
        ) : null}
        <div>{children}</div>
      </Container>
    </section>
  )
}

export default Section
