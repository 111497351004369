import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col, Button } from "reactstrap"
import { Slide } from "../components/Interface/Carousel/Carousel"
import Slider from "../components/Interface/Carousel/Slider"
import Section from "./Interface/Section"
import constants from "../../utils/constants"

const brandFullName = constants.BRAND_FULLNAME

const Notifications = () => {
  const data = useStaticQuery(graphql`
    query HomeNotificationsQuery {
      wpgraphql {
        pageBy(pageId: 7) {
          ACFNotificationCarousel {
            notificationCarousel {
              notificationType
              notificationTypeText
              notificationHeadline
              notificationText
              notificationLink {
                target
                title
                url
              }
              notificationButton
              newNotificationImage {
                altText
                srcSet
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  const notify =
    data.wpgraphql.pageBy.ACFNotificationCarousel.notificationCarousel

  return (
    <Section title={`What's New at ${constants.BRAND_FULLNAME}`}>
      <Slider animationTime={500} slideInterval={10000}>
        {notify &&
          notify.map((note, i) => (
            <Slide index={i} key={i}>
              <>
                <Row>
                  <Col md="6" className="mb-3" key="1">
                    {note && note.notificationType ? (
                      <h3>{note.notificationType}</h3>
                    ) : null}
                    {note && note.notificationTypeText ? (
                      <p>{note.notificationTypeText}</p>
                    ) : null}
                    {note && note.notificationHeadline ? (
                      <h4>
                        <a href={note.notificationLink.url}>
                          {note.notificationHeadline}
                        </a>
                      </h4>
                    ) : null}
                    {note && note.notificationText ? (
                      <div className="hide-small">
                        <p>{note.notificationText}</p>
                      </div>
                    ) : null}
                    {note && note.notificationButton ? (
                      <Button color="primary">{note.notificationButton}</Button>
                    ) : null}
                  </Col>
                  <Col md="6" key="2">
                    <img
                      href={note.notificationLink.url}
                      src={note.newNotificationImage.sourceUrl}
                      alt={note.newNotificationImage.altText}
                      srcSet={note.newNotificationImage.srcSet}
                    />
                  </Col>
                </Row>
              </>
            </Slide>
          ))}
      </Slider>
    </Section>
  )
}

export default Notifications
