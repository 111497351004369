import React from 'react';

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 1,
      transitionDuration: props.animationTime || 5000
    };
    this.elements = this.createClones(props.children);
    this.timeoutObject = null;
  }

  createClones(children) {
    const length = children.length;
    const last = Object.assign({}, children[length - 1]);
    const first = Object.assign({}, children[0]);

    return [last, ...children, first];
  }

  componentDidMount() {
    this.startSlideInterval();
  }

  startSlideInterval() {
    if (this.props.slideInterval) {
      this.timeoutObject = setTimeout(this.next, this.props.slideInterval);
    }
  }

  clearSlideInterval() {
    if (this.timeoutObject) {
      clearTimeout(this.timeoutObject);
    }
  }

  handleTransitionEnd = () => {
    const length = this.elements.length;
    const activeIndex = this.state.activeIndex;

    if (activeIndex === 0) {
      this.setState({ transitionDuration: 0, activeIndex: length - 2 });
    } else if (activeIndex === length - 1) {
      this.setState({ transitionDuration: 0, activeIndex: 1 });
    }

    this.clearSlideInterval();
    this.startSlideInterval();
  };

  setActiveIndex = newIndex => {
    if (newIndex >= 0 && newIndex <= this.elements.length - 1) {
      this.setState({
        transitionDuration: this.props.animationTime,
        activeIndex: newIndex
      });
    }
  };

  next = () => {
    this.setActiveIndex(this.state.activeIndex + 1);
  };

  previous = () => {
    this.setActiveIndex(this.state.activeIndex - 1);
  };

  render() {
    const { activeIndex, transitionDuration } = this.state;
    const elements = this.elements;
    const translation = -100 * activeIndex / elements.length;

    const style = {
      transform: "translateX(" + translation.toString() + "%)",
      transitionDuration: transitionDuration / 1000 + "s",
      width: elements.length * 100 + "%"
    };

    return (
      <div className="caro-container">
        <div
          className="caro-items"
          style={style}
          onTransitionEnd={this.handleTransitionEnd}
        >
          {React.Children.map(elements, (element, index) => {
            return (
              <div className="caro-item" key={index}>
                {element}
              </div>
            );
          })}
        </div>
        <div className="indicators">
          <Arrow onArrowClick={this.previous} direction="left" />

          <div className="dots">
            {this.props.children.map((child, index) => {
              let indicatorClass = "indicators-item";

              if (index + 1 === activeIndex) {
                indicatorClass += " active";
              }

              return (
                <button
                  className={indicatorClass}
                  key={index + 1}
                  onClick={() => this.setActiveIndex(index + 1)}
                />
              );
            })}
          </div>
          <Arrow onArrowClick={this.next} direction="right" />
        </div>
      </div>
    );
  }
}

//Arrow component
const Arrow = props => {
  const iconClass = "lnr lnr-chevron-" + props.direction;

  return (
    <button onClick={props.onArrowClick} className={`${props.direction} arrow-item`}>
      <i className={iconClass} />
    </button>
  );
};