import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Row, Col, Button } from "reactstrap"
import { Slide } from "./Interface/Carousel/Carousel"
import Section from "./Interface/Section"
import constants from "../../utils/constants"

const WORDPRESS_URL = constants.WORDPRESS_URL

const brandShortName = constants.BRAND_SHORTNAME

const HomeNotifications = () => {
  const data = useStaticQuery(graphql`
    query HomeNotificationsQuery2 {
      wpgraphql {
        pageBy(pageId: 7) {
          ACFNotificationCarousel {
            notificationCarousel {
              notificationType
              notificationTypeText
              notificationHeadline
              notificationText
              notificationLink {
                target
                title
                url
              }
              notificationButton
              newNotificationImage {
                altText
                srcSet
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  const notify =
    data.wpgraphql.pageBy.ACFNotificationCarousel.notificationCarousel

  // const url =
  //   data.wpgraphql.pageBy.ACFNotificationCarousel.notificationCarousel
  //     .notificationLink.url

  // console.log(url)

  // const createLocalLink = url => {
  //   if (`#` === url) {
  //     return null
  //   }
  //   if (url.includes("sewing-classes")) {
  //     const date = new Date()
  //     url = `${url}/${date.getFullYear()}/${date.getMonth() + 1}`
  //   }
  //   return url.replace(WORDPRESS_URL, ``)
  // }

  return (
    <Section title={`What's New at ${constants.BRAND_SHORTNAME}`}>
      {notify &&
        notify.map((note, i) => {
          if (i % 2 === 0) {
            return (
              <>
                <Row className="mb-3" index={i} key={i}>
                  <Col>
                    {note && note.notificationType ? (
                      <h3 className="text-center">{note.notificationType}</h3>
                    ) : null}
                    {note && note.notificationTypeText ? (
                      <p className="text-center">{note.notificationTypeText}</p>
                    ) : null}
                    {note && note.notificationHeadline ? (
                      <h4 className="text-center">
                        <a href={note.notificationLink.url}>
                          {note.notificationHeadline}
                        </a>
                      </h4>
                    ) : null}
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col md="6" className="mb-3 order-md-1" key="1">
                    {note && note.notificationText ? (
                      <div className="hide-small">
                        <p>{note.notificationText}</p>
                      </div>
                    ) : null}
                    {note && note.notificationButton ? (
                      <Link to={note.notificationLink.url}>
                        <Button color="secondary my-3">
                          {note.notificationButton}
                        </Button>
                      </Link>
                    ) : null}
                  </Col>
                  <Col md="6" key="2" className="mb-3 order-xs-1 order-md-2">
                    <img
                      href={note.notificationLink.url}
                      src={note.newNotificationImage.sourceUrl}
                      alt={note.newNotificationImage.altText}
                      srcSet={note.newNotificationImage.srcSet}
                      className="shadow"
                    />
                  </Col>
                </Row>
                <hr></hr>
              </>
            )
          } else {
            return (
              <>
                <Row className="mb-3 align-items-end" index={i} key={i}>
                  <Col>
                    {note && note.notificationType ? (
                      <h3 className="text-center">{note.notificationType}</h3>
                    ) : null}
                    {note && note.notificationTypeText ? (
                      <p className="text-center">{note.notificationTypeText}</p>
                    ) : null}
                    {note && note.notificationHeadline ? (
                      <h4 className="text-center">
                        <a href={note.notificationLink.url}>
                          {note.notificationHeadline}
                        </a>
                      </h4>
                    ) : null}
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col md="6" key="2">
                    <img
                      href={note.notificationLink.url}
                      src={note.newNotificationImage.sourceUrl}
                      alt={note.newNotificationImage.altText}
                      srcSet={note.newNotificationImage.srcSet}
                      className="shadow"
                    />
                  </Col>
                  <Col md="6" className="mb-3" key="1">
                    {note && note.notificationText ? (
                      <div className="hide-small">
                        <p className="text-right">{note.notificationText}</p>
                      </div>
                    ) : null}
                    {note && note.notificationButton ? (
                      <div className="text-right">
                        <Link to={note.notificationLink.url}>
                          <Button color="secondary my-3">
                            {note.notificationButton}
                          </Button>
                        </Link>
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <hr></hr>
              </>
            )
          }
        })}
    </Section>
  )
}

export default HomeNotifications
