import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledBackgroundSection from "../components/Interface/HeroImage"
// import LocationsTab from "../components/LocationsTab"
import HomeNotifications from "../components/HomeNotifications"
import Notifications from "../components/Notifications"
import HomePageContent from "../components/HomePageContent"
// import FeaturedSlider from "../components/FeaturedSlider"
import ContactUs from "../components/Forms/ContactUs"

import SvgBackground from "../components/BackGrounds/SvgBackground"

// import constants from "../../utils/constants"

// console.log(title)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HeroPageSeo {
      wpgraphql {
        pageBy(pageId: 7) {
          seo {
            metaDesc
            title
          }
        }
      }
    }
  `)

  const title = data.wpgraphql.pageBy.seo.title
  const description = data.wpgraphql.pageBy.seo.metaDesc

  // var decodeHTML = function(html) {
  //   var txt = document.createElement("textarea")
  //   txt.innerHTML = html
  //   return txt.value
  // }

  // const titleDecoded = decodeHTML(title)
  // const descriptionDecoded = decodeHTML(description)

  return (
    <Layout location="home">
      <SEO title={title} description={description} />
      <div className="wrapper-home">
        {/* <StyledBackgroundSection /> */}
        <SvgBackground />
        <HomeNotifications />
        {/* <BlogCarousel /> */}
        {/* <Notifications /> */}
        {/* <FeaturedSlider /> */}
        <HomePageContent />
        {/* <LocationsTab /> */}
        <ContactUs />
      </div>
    </Layout>
  )
}

export default IndexPage
