import React from "react"
import { Jumbotron, Container, Row, Col, Button } from "reactstrap"
import { Link } from "gatsby"

import { brandFullName } from "constants"

const SvgBackground = () => (
  <div>
    <Jumbotron className="gradient-by-query">
      <Container>
        <Row>
          <Col md={4} className="my-auto">
            <img
              src={require("../../images/logo-people.svg")}
              alt={brandFullName}
              className="mx-auto d-block"
            />
          </Col>
          <Col md={1}></Col>
          <Col>
            <h1>A New Kind of Web Development Agency</h1>
            <p className="lead">
              Simplur's modern, proprietary web site platform enables us to
              bring BIG results to your small business e-commerce efforts.
            </p>
            <hr className="my-2" />
            <p>
              Find out more below about how our "Simple Pluralism" model enables
              outstanding results that keep getting better.
            </p>
            <p>Contact us for more information or read on below...</p>
            <p className="lead">
              <Link to="/contact/">
                <Button color="cta">Contact Us</Button>
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  </div>
)

export default SvgBackground
