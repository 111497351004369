import React from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { graphql, useStaticQuery, Link } from "gatsby"
// import Logo from "./Logo"
import constants from "../../utils/constants"

const brandFullName = constants.BRAND_FULLNAME
const brandFullNameLogo = brandFullName + " logo"

const HeroLinks = () => {
  const data = useStaticQuery(graphql`
    query HeroLinksQuery {
      wpgraphql {
        pageBy(pageId: 7) {
          ACFHomePageCTA {
            micrositePageCta {
              cardTitle
              cardSubtitle
              cardLink {
                title
                url
              }
            }
          }
          ACFTopAlert {
            topAlert
          }
        }
      }
    }
  `)

  const heroLinks = data.wpgraphql.pageBy.ACFHomePageCTA.micrositePageCta
  const topAlert = data.wpgraphql.pageBy.ACFTopAlert.topAlert

  console.log(heroLinks)

  return (
    <>
      <Row>
        <Col>
          {" "}
          {topAlert ? (
            <strong>
              {" "}
              <div
                className="alert alert-primary text-center mx-auto"
                dangerouslySetInnerHTML={{ __html: topAlert }}
              />
            </strong>
          ) : null}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <h1 className="text-center">{brandFullName}</h1>
        </Col>
      </Row>
      <Row className="my-auto">
        <Col lg={5}>
          <img
            src={require("../images/logo-people.svg")}
            alt={brandFullNameLogo}
            className="mx-auto d-block"
          />
        </Col>
        {heroLinks &&
          heroLinks.map((card, i) => (
            <Col key={i}>
              <Card index={i}>
                <div className="bg-gradient-secondary-80">
                  <CardBody>
                    {card && card?.cardTitle ? <h4>{card.cardTitle}</h4> : null}

                    {card && card?.cardSubtitle ? (
                      <p>{card.cardSubtitle}</p>
                    ) : null}
                    {card && card.cardLink ? (
                      <Link to={card.cardLink.url}>
                        <Button color="primary">{card.cardTitle}</Button>
                      </Link>
                    ) : null}
                  </CardBody>
                </div>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  )
}

export default HeroLinks
