import React from "react"
import Section from "../Interface/Section"
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap"

const ContactUs = () => {
  return (
    <>
      <Section title="Contact Us">
        <Row>
          <Col md="10" className="mx-auto">
            <Form
              name="Contact Form"
              method="post"
              action="/thanks"
              data-netlify-honeypot="bot-field"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="Contact Form" />
              <p class="netlify-hidden">
                <label>
                  Don’t fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </p>

              <FormGroup>
                <Label for="Last Name">Name</Label>
                <Input type="text" name="your-name" id="Name" />
              </FormGroup>

              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input type="email" name="your-email" id="Email" />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Phone Number</Label>
                <Input type="phone" name="your-phone" id="Phone" />
              </FormGroup>
              <FormGroup>
                <Label for="Your Message">Your Message</Label>
                <Input type="textarea" name="your-message" id="yourMessage" />
              </FormGroup>

              <Button color="cta" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default ContactUs
