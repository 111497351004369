import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "reactstrap"

import Section from "./Interface/Section"
import constants from "../../utils/constants"

const HomePageContent = () => {
  const data = useStaticQuery(graphql`
    query HomePageContentQuery {
      wpgraphql {
        pageBy(pageId: 7) {
          ACFHomePageContent {
            homePageContent {
              headline
              textAndLinks
            }
          }
          ACFHomeProductsServices {
            homePageProductServices {
              image {
                altText
                sourceUrl
                srcSet
              }
              title
              links {
                linkPage {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const contents = data.wpgraphql.pageBy.ACFHomePageContent.homePageContent

  const contentGroup =
    data.wpgraphql.pageBy.ACFHomeProductsServices.homePageProductServices

  return (
    <>
      <Container>
        <Section title="Simplur's Web Site Development Platform">
          <Row>
            {contents &&
              contents.map((content, i) => (
                <Col sm={12} md={6} key={i}>
                  {contents && content.headline ? (
                    <h4>{content.headline}</h4>
                  ) : null}
                  {contents && content.textAndLinks ? (
                    <p
                      dangerouslySetInnerHTML={{ __html: content.textAndLinks }}
                    />
                  ) : null}
                </Col>
              ))}
          </Row>
        </Section>
      </Container>
      <Container>
        <Row className="justify-content-center">
          {contentGroup &&
            contentGroup.map((group, i) => (
              <Col xs={6} md={2} className="text-center" key={i}>
                <img
                  src={group.image.sourceUrl}
                  alt={group.image.altText}
                  srcSet={group.image.srcSet}
                  className="mb-3 p-2"
                />
                {contentGroup && group.title ? <h5>{group.title}</h5> : null}
                {/* {contentGroup &&
                  group.links.map((link, index) => (
                    <span key={index}>
                      <a href={link.linkPage.url}>{link.linkPage.title}</a>
                      {index < group.links.length - 1 && " | "}
                    </span>
                  ))} */}
              </Col>
            ))}
        </Row>
      </Container>
    </>
  )
}

export default HomePageContent
