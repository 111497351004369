import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import { Container } from "reactstrap"
import BackgroundImage from "gatsby-background-image"

import HeroLinks from "../HeroLinks"

// const sewingClassesLink = () => {
//   const date = new Date()
//   return `sewing-classes/${date.getFullYear()}/${date.getMonth() + 1}`
// }

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(
          relativePath: { eq: "webpage-background_1920x1080_acf_cropped.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      const backgroundFluidImageStack = [
        imageData,
        `linear-gradient(rgba(255, 255, 255, 0.73), rgba(255,255,255,.2))`,
      ].reverse()
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={backgroundFluidImageStack}
          backgroundColor={`#fff`}
          durationFadeIn={300}
        >
          <div className="hero-content">
            {" "}
            <Container className="h-100">
              <HeroLinks />
            </Container>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  /* .hero-content {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  } */
  width: 100%;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh - 76px);
`

export default StyledBackgroundSection
